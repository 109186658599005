var icons = [
    {
    "name": "Inbound Call",
    "id": "inboundcall",
    "unicode": 40,
    "created": 1,
    "filter": [
        "phone"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Outbound Call",
    "id": "outboundcall",
    "unicode": "004f",
    "created": 1,
    "filter": [
        "phone"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Missed Call",
    "id": "missedcall",
    "unicode": "004d",
    "created": 1,
    "filter": [
        "phone"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Hang Up",
    "id": "hangup",
    "unicode": "0048",
    "created": 1,
    "filter": [
        "phone"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Rejected Call",
    "id": "rejectedcall",
    "unicode": 42,
    "created": 1,
    "filter": [
        "phone"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Signal Strength 0",
    "id": "signal0",
    "unicode": 24,
    "created": 1,
    "filter": [
        "signal"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Signal Strength 1",
    "id": "signal1",
    "unicode": 25,
    "created": 1,
    "filter": [
        "signal"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Signal Strength 2",
    "id": "signal2",
    "unicode": 26,
    "created": 1,
    "filter": [
        "signal"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Signal Strength 3",
    "id": "signal3",
    "unicode": 27,
    "created": 1,
    "filter": [
        "signal"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Signal Strength 4",
    "id": "signal4",
    "unicode": 28,
    "created": 1,
    "filter": [
        "signal"
    ],
    "categories": [
        "Web Application Icons"
    ]
    },
    {
    "name": "Signal Strength 5",
    "id": "signal5",
    "unicode": 29,
    "created": 1,
    "filter": [
        "signal"
    ],
    "categories": [
        "Web Application Icons"
    ]
    }
];

export default icons;